import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import DataDrivenMarketingComponent from '@/components/DataDrivenMarketingComponent.vue';
import DataDrivenPedagogicoComponent from '@/components/DataDrivenPedagogicoComponent.vue';
import { userActionStore } from '@/store';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'tab_adm_operacional';
        this.companyId = 3;
    }
    get userActionsVisualizarAbaMarketingDataDrivenAllow() {
        return userActionStore.visualizarAbaMarketingDataDrivenAllow;
    }
    get userActionsVisualizarAbaPedagogicoDataDrivenAllow() {
        return userActionStore.visualizarAbaPedagogicoDataDrivenAllow;
    }
    get userActionsNotImplementedAllow() {
        return false; // implementar outras abas
    }
    get getUrlAdmOperacional() {
        return `https://lookerstudio.google.com/embed/reporting/1bbfd68b-68d2-4037-a824-cfe26bb9d5c5/page/p_771xsqd8kd?s=oyUwvW8PtMQ&params=%7B%22df1947%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${this.companyId}%22%7D`;
    }
    get getUrlAdmPegagogico() {
        return `https://lookerstudio.google.com/embed/reporting/5169fa5b-ae75-47df-b972-41e645a7e232/page/p_771xsqd8kd?params=%7B"df2192":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${this.companyId}"%7D`;
    }
    get getUrlComercial() {
        return `https://lookerstudio.google.com/embed/reporting/b2ff4f63-782f-4707-820c-7dc517b404ad/page/p_idg6x0bpkd?params=%7B"df2178":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${this.companyId}"%7D`;
    }
    get getUrlPedagogico() {
        return `https://lookerstudio.google.com/embed/reporting/0fada8a4-1239-4b7d-8e90-ee38f62c80ee/page/p_avrxxxoood?s=ql7d7V0LusE&params=%7B"df2272":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${this.companyId}"%7D`;
    }
    get getUrlFinanceiro() {
        return `https://lookerstudio.google.com/embed/reporting/551fd06a-3f39-4599-a7f3-bdfc330b5045/page/p_mqovqgzrod?params=%7B%22df2328%22:%22include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${this.companyId}%22%7D`;
    }
    get getUrlMarketing() {
        return `https://lookerstudio.google.com/embed/reporting/7591dca0-e714-4fab-821d-3ed6f99798f5/page/p_jfmy1yduod?params=%7B"df2339":"include%25EE%2580%25801%25EE%2580%2580IN%25EE%2580%2580${this.companyId}"%7D`;
    }
};
List = __decorate([
    Component({
        components: {
            DataDrivenMarketingComponent,
            DataDrivenPedagogicoComponent
        }
    })
], List);
export default List;
