import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import BarraProgressoComponent from '@/components/BarraProgressoComponent.vue';
import GraficoDonutComponent from '@/components/GraficoDonutComponent.vue';
import PeriodSelectorComponent from './PeriodSelectorComponent.vue';
import Performance from '@/views/main/novoDashboard/Performance.vue';
import { dashboardStore } from '@/store';
import Highcharts from 'highcharts';
import exportingModule from 'highcharts/modules/exporting';
import exportDataModule from 'highcharts/modules/export-data';
exportingModule(Highcharts);
exportDataModule(Highcharts);
let DataDrivenPedagogicoComponent = class DataDrivenPedagogicoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loadingFiltros = false;
        this.loadingPerformances = false;
        this.loadingResumoTurmas = false;
        this.loadingPresencaPorProfessor = false;
        this.loadingGraficoDonuts = false;
        this.loadingHomeworkPorTurma = false;
        this.loadingAlunosPorSemana = false;
        this.resumoPedagogicoHeaders = [
            { text: 'Presenças', value: 'presencas' },
            { text: '% Presenças', value: 'presencas_percent' },
            { text: 'Faltas', value: 'faltas' },
            { text: '% Faltas', value: 'faltas_percent' },
            { text: 'Não apontado', value: 'nao_apontados' },
            { text: '% Não apontado', value: 'nao_apontados_percent' },
            { text: 'Total de aulas', value: 'total_aulas' },
            { text: 'Alunos diferentes', value: 'alunos_diferentes' },
        ];
        this.homeworkPorTurmaHeaders = [
            { text: 'Codigo referencia', value: 'turma_codigo_referencia' },
            { text: 'Professor', value: 'nome_professor' },
            { text: 'Livro', value: 'livro_nome' },
            { text: 'Alunos na turma', value: 'numero_aluno' },
            { text: `${this.getNomeHomework()} entregue`, value: 'homework_entregue' },
            { text: `${this.getNomeHomework()} não entregue`, value: 'homework_nao_entregue' },
        ];
        this.presencaPorProfessorConfig = {
            chart: {
                type: 'bar',
                height: 400
            },
            title: {
                text: null
            },
            xAxis: {
                categories: [],
                title: { text: null },
                labels: { style: { fontSize: '12px' } }
            },
            yAxis: {
                min: 0,
                title: { text: 'Presença' },
                labels: { enabled: true }
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            // @ts-ignore
                            return this.y.toFixed(2) + ' %';
                        },
                        style: { fontSize: '14px', fontWeight: 'bold' }
                    }
                }
            },
            series: [
                {
                    name: 'Quantidade',
                    data: [],
                    colorByPoint: true // Permite cores diferentes automaticamente
                }
            ],
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y} %</b>'
            },
            credits: {
                enabled: false
            }
        };
        this.quantidadeAlunosPorSemanaConfig = {
            chart: {
                type: 'column',
                height: 500
            },
            title: {
                text: 'Quantidade e Porcentagem por Dia da Semana'
            },
            xAxis: {
                categories: [
                    'Segunda-feira',
                    'Terça-feira',
                    'Quarta-feira',
                    'Quita-feira',
                    'Sexta-feira',
                    'Sábado',
                    'Domingo',
                ],
                title: { text: 'Dia da Semana' }
            },
            yAxis: [
                {
                    title: { text: 'Quantidade' },
                    min: 0
                },
                {
                    title: { text: 'Porcentagem' },
                    min: 0,
                    max: 100,
                    opposite: true
                }
            ],
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            // @ts-ignore
                            if (this.series.name === 'Porcentagem') {
                                // @ts-ignore
                                return this.y.toFixed(2) + ' %';
                            }
                            // @ts-ignore
                            return this.y;
                        },
                        style: { fontSize: '12px' }
                    }
                }
            },
            series: [
                {
                    name: 'Quantidade',
                    data: [],
                    color: '#6f18a8',
                    tooltip: { valueSuffix: ' alunos' }
                },
                {
                    name: 'Porcentagem',
                    data: [],
                    color: '#ff5733',
                    yAxis: 1,
                    tooltip: { valueSuffix: '%' }
                }
            ],
            legend: {
                enabled: true
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                shared: true,
                formatter: function () {
                    // @ts-ignore
                    return `<b>${this.x}</b><br>${this.points.map(point => `${point.series.name}: <b>${point.y.toFixed(2)}</b>${point.series.tooltipOptions.valueSuffix || ''}`).join('<br>')}`;
                }
            },
            credits: {
                enabled: false
            }
        };
        this.selectPeriodDate = [];
        this.pedagogicoProfessorFiltrosOptions = [];
        this.pedagogicoFiltrosSelectedProfessor = [];
        this.performances = {};
        this.resumoPedagogicoItems = [];
        this.presencaByProfessorItems = [];
        this.graficoDonutStatusAtividades = [];
        this.graficoDonutStatusAlunosNaTurma = [];
        this.homeworkPorTurmaItems = [];
    }
    async buscar() {
        if (this.loadingFiltros) {
            return;
        }
        const payload = {
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            professores: this.pedagogicoFiltrosSelectedProfessor
        };
        this.loadingFiltros = true;
        this.loadingPerformances = true;
        this.loadingResumoTurmas = true;
        this.loadingPresencaPorProfessor = true;
        this.loadingGraficoDonuts = true;
        this.loadingHomeworkPorTurma = true;
        this.loadingAlunosPorSemana = true;
        const promise1 = dashboardStore.getDataDrivenPedagogicoPerformance(payload);
        const promise2 = dashboardStore.getDataDrivenPedagogicoResumo(payload);
        const promise3 = dashboardStore.getDataDrivenPedagogicoPresencaByProfessor(payload);
        const promise4 = dashboardStore.getDataDrivenPedagogicoGraficoDonut(payload);
        const promise5 = dashboardStore.getDataDrivenPedagogicoHomeworkPorTurma(payload);
        const promise6 = dashboardStore.getDataDrivenPedagogicoTurmaBySemana(payload);
        await Promise.all([
            promise1.then((item) => {
                this.performances = item;
                this.loadingPerformances = false;
            }),
            promise2.then((item) => {
                this.resumoPedagogicoItems = item;
                this.loadingResumoTurmas = false;
            }),
            promise3.then((item) => {
                this.presencaByProfessorItems = item;
                const sortedByProfessor = this.presencaByProfessorItems.sort((a, b) => b.value - a.value);
                const categories = sortedByProfessor.map(item => item.nome_professor);
                const values = sortedByProfessor.map(item => item.presenca_percent);
                this.presencaPorProfessorConfig.series[0] = {
                    name: 'Quantidade',
                    data: values,
                    colorByPoint: true
                };
                this.presencaPorProfessorConfig.xAxis = {
                    categories,
                    title: { text: null },
                    labels: { style: { fontSize: '12px' } }
                };
                this.loadingPresencaPorProfessor = false;
            }),
            promise4.then((item) => {
                const donutItems = item;
                this.graficoDonutStatusAtividades = donutItems.status_atividades;
                this.graficoDonutStatusAlunosNaTurma = donutItems.status_do_aluno_na_turma;
                this.loadingGraficoDonuts = false;
            }),
            promise5.then((item) => {
                this.homeworkPorTurmaItems = item;
                this.loadingHomeworkPorTurma = false;
            }),
            promise6.then((item) => {
                const dataListTurmaBySemana = item;
                const categoriesTurmaBySemana = [...new Set(dataListTurmaBySemana.map(item => item.dia_semana))];
                const quantidadeData = categoriesTurmaBySemana.map(day => {
                    const entry = dataListTurmaBySemana.find(item => item.dia_semana === day);
                    return entry ? entry.quantidade : 0;
                });
                const porcentagemData = categoriesTurmaBySemana.map(day => {
                    const entry = dataListTurmaBySemana.find(item => item.dia_semana === day);
                    return entry ? entry.percentual : 0;
                });
                this.quantidadeAlunosPorSemanaConfig.series = [
                    {
                        name: 'Quantidade',
                        data: quantidadeData,
                        color: '#6f18a8',
                        tooltip: { valueSuffix: ' alunos' }
                    },
                    {
                        name: 'Porcentagem',
                        data: porcentagemData,
                        color: '#ff5733',
                        yAxis: 1,
                        tooltip: { valueSuffix: '%' }
                    }
                ];
                this.loadingAlunosPorSemana = false;
            }),
        ]);
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.highchartsComponentProfessores.chart.update(this.presencaPorProfessorConfig, true);
            // @ts-ignore
            this.$refs.highchartsComponentAlunosPorSemana.chart.update(this.quantidadeAlunosPorSemanaConfig, true);
        });
        this.pedagogicoProfessorFiltrosOptions = await dashboardStore.getDataDrivenFiltroOptions({ data_inicial: this.selectPeriodDate[0], data_final: this.selectPeriodDate[1] });
        this.pedagogicoFiltrosSelectedProfessor = [];
        this.loadingFiltros = false;
    }
    async mounted() {
        this.$nextTick(async () => {
            this.loadingFiltros = true;
            this.pedagogicoProfessorFiltrosOptions = await dashboardStore.getDataDrivenFiltroOptions({ data_inicial: this.selectPeriodDate[0], data_final: this.selectPeriodDate[1] });
            this.loadingFiltros = false;
        });
    }
};
DataDrivenPedagogicoComponent = __decorate([
    Component({
        components: {
            highcharts: Chart,
            BarraProgressoComponent,
            PeriodSelectorComponent,
            GraficoDonutComponent,
            Performance
        },
    })
], DataDrivenPedagogicoComponent);
export default DataDrivenPedagogicoComponent;
