import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
let BarraProgressoComponent = class BarraProgressoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.configChartBarra = {
            chart: {
                type: 'bar',
                height: 100,
            },
            title: {
                text: null,
            },
            xAxis: {
                categories: [''],
                visible: false,
            },
            yAxis: {
                min: 0,
                max: 100,
                title: { text: null },
                labels: { enabled: false },
                gridLineWidth: 0,
                plotBands: [
                    {
                        from: 50,
                        to: 51,
                        color: 'black',
                        zIndex: 3,
                        label: { text: 'Meta', style: { color: 'black' }, y: 90 },
                    },
                ],
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    grouping: false,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            // @ts-ignore
                            return this.y.toFixed(2) + '%';
                        },
                        inside: true,
                        style: { color: 'white', fontSize: '14px' },
                    },
                },
            },
            series: [
                {
                    name: 'Fundo',
                    data: [100],
                    color: '#d6d6d6',
                    zIndex: 1,
                    enableMouseTracking: false,
                    dataLabels: { enabled: false },
                    borderRadius: 0,
                },
                {
                    name: 'Progresso',
                    data: [0],
                    color: '#6f18a8',
                    zIndex: 2,
                    borderRadius: 0,
                }
            ],
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false,
            },
            tooltip: { enabled: false },
            credits: { enabled: false },
        };
    }
    async onPropData(newVal, oldVal) {
        if (newVal) {
            const metaPercent = (this.propMeta / this.propQuantidade) > 1 ? 99 : (this.propMeta / this.propQuantidade) * 100;
            this.configChartBarra.series[1].data = [(this.propQuantidade / this.propMeta) * 100];
            this.configChartBarra.yAxis.plotBands[0].from = metaPercent;
            this.configChartBarra.yAxis.plotBands[0].to = metaPercent + 1;
            this.configChartBarra.yAxis.plotBands[0].label = { text: `${this.propMeta.toFixed(2)}`, style: { color: 'black' }, y: 90 };
            this.$nextTick(() => {
                // @ts-ignore
                this.$refs.highchartsComponent.chart.update(this.configChartBarra, true);
            });
        }
    }
    async mounted() {
        const metaPercent = (this.propMeta / this.propQuantidade) > 1 ? 99 : (this.propMeta / this.propQuantidade) * 100;
        this.configChartBarra.series[1].data = [(this.propQuantidade / this.propMeta) * 100];
        this.configChartBarra.yAxis.plotBands[0].from = metaPercent;
        this.configChartBarra.yAxis.plotBands[0].to = metaPercent + 1;
        this.configChartBarra.yAxis.plotBands[0].label = { text: `${this.propMeta.toFixed(2)}`, style: { color: 'black' }, y: 90 };
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.highchartsComponent.chart.update(this.configChartBarra, true);
        });
    }
};
__decorate([
    Prop({ default: 0 })
], BarraProgressoComponent.prototype, "propQuantidade", void 0);
__decorate([
    Prop({ default: 0 })
], BarraProgressoComponent.prototype, "propMeta", void 0);
__decorate([
    Watch('propQuantidade')
], BarraProgressoComponent.prototype, "onPropData", null);
BarraProgressoComponent = __decorate([
    Component({
        components: {
            highcharts: Chart,
        },
    })
], BarraProgressoComponent);
export default BarraProgressoComponent;
