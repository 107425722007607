import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
let GraficoDonutComponent = class GraficoDonutComponent extends Vue {
    constructor() {
        super(...arguments);
        this.configChartDonut = {
            chart: {
                type: 'pie',
                height: 300
            },
            title: {
                text: null
            },
            plotOptions: {
                pie: {
                    innerSize: '40%',
                    dataLabels: {
                        enabled: true,
                        distance: -40,
                        formatter: function () {
                            // @ts-ignore
                            return this.y.toFixed(2) + '%';
                        },
                        style: { fontSize: '14px', fontWeight: 'bold' }
                    },
                    borderWidth: 5,
                    showInLegend: true
                }
            },
            series: [
                {
                    name: 'Valores',
                    data: [
                        { name: 'Categoria 1', y: 40 },
                        { name: 'Categoria 2', y: 30 },
                        { name: 'Categoria 3', y: 20 },
                        { name: 'Categoria 4', y: 10 }
                    ]
                }
            ],
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                itemMarginTop: 5,
                itemMarginBottom: 5,
                labelFormatter: function () {
                    // @ts-ignore
                    return this.name + ' (' + this.y + '%)';
                }
            },
            exporting: {
                enabled: true
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}%</b>'
            },
            credits: {
                enabled: false
            }
        };
    }
    async onPropData(newVal, oldVal) {
        if (newVal) {
            const total = this.propItems.reduce((sum, item) => sum + item.y, 0);
            this.configChartDonut.series[0].data = this.propItems.map(item => ({
                name: item.name,
                y: parseFloat(((item.y / total) * 100).toFixed(2))
            }));
            this.$nextTick(() => {
                // @ts-ignore
                this.$refs.highchartsComponent.chart.update(this.configChartDonut, true);
            });
        }
    }
    async mounted() {
        if (this.propItems) {
            const total = this.propItems.reduce((sum, item) => sum + item.y, 0);
            this.configChartDonut.series[0].data = this.propItems.map(item => ({
                name: item.name,
                y: parseFloat(((item.y / total) * 100).toFixed(2))
            }));
        }
        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.highchartsComponent.chart.update(this.configChartDonut, true);
        });
    }
};
__decorate([
    Prop({ default: [] })
], GraficoDonutComponent.prototype, "propItems", void 0);
__decorate([
    Watch('propItems')
], GraficoDonutComponent.prototype, "onPropData", null);
GraficoDonutComponent = __decorate([
    Component({
        components: {
            highcharts: Chart,
        },
    })
], GraficoDonutComponent);
export default GraficoDonutComponent;
