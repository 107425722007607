import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import BarraProgressoComponent from '@/components/BarraProgressoComponent.vue';
import GraficoDonutComponent from '@/components/GraficoDonutComponent.vue';
import PeriodSelectorComponent from './PeriodSelectorComponent.vue';
import { marketingDigitalStore } from '@/store';
import Highcharts from 'highcharts';
import exportingModule from 'highcharts/modules/exporting';
import exportDataModule from 'highcharts/modules/export-data';
exportingModule(Highcharts);
exportDataModule(Highcharts);
let DataDrivenMarketingComponent = class DataDrivenMarketingComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loadingProgressBars = false;
        this.loadingPerformanceCampanhas = false;
        this.loadingRetorno = false;
        this.loadingMidiaOrigem = false;
        this.loadingStatusCupom = false;
        this.loadingFiltros = false;
        this.configChartPerformanceCampanhas = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'line',
            },
            title: {
                text: '',
            },
            yAxis: {
                title: {
                    text: 'Número de cupons'
                }
            },
            xAxis: {
                type: 'datetime',
                min: new Date().getTime(),
                max: new Date().getTime(),
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 0
                }
            },
            series: [{
                    name: 'Cupons',
                    data: []
                }],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG'
                        ]
                    }
                }
            }
        };
        this.configChartBar = {
            chart: {
                type: 'column',
                height: 400
            },
            title: {
                text: null
            },
            xAxis: {
                categories: [],
                title: { text: null }
            },
            yAxis: {
                min: 0,
                title: { text: 'Valores' },
                labels: { enabled: true }
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            // @ts-ignore
                            return this.y.toFixed(2);
                        },
                        style: { fontSize: '14px', fontWeight: 'bold' }
                    }
                }
            },
            series: [
                {
                    name: 'Quantidade',
                    data: []
                }
            ],
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b>'
            },
            credits: {
                enabled: false
            }
        };
        this.performanceComercialHeaders = [
            { text: 'Qtd Cupons', value: 'qtd_cupons' },
            { text: 'Distribuido CDA', value: 'distribuido_cda' },
            { text: 'Distribuido', value: 'percent_distribuido' },
            { text: 'Cupom Agendado', value: 'cupom_agendado' },
            { text: 'Agendado x Distribuido', value: 'agendado_x_distribuido' },
            { text: 'Agendado x Cupom', value: 'agendado_x_cupom' },
            { text: 'Gerou visita', value: 'gerou_visita' },
            { text: 'Visita x Agendado', value: 'visita_x_agendado' },
            { text: 'Gerou visita x Cupom', value: 'gerou_visita_x_cupom' },
            { text: 'Matriculas', value: 'matriculas' },
            { text: 'Matriculas x Visita', value: 'matriculas_x_visita' },
            { text: 'Matriculas x Cupom', value: 'matriculas_x_cupom' },
        ];
        this.statusCuponsHeaders = [
            { text: 'Data de criação', value: 'cupom_data_cadastro' },
            { text: 'Nome', value: 'nome_pessoa' },
            { text: 'CDA', value: 'cda_nome' },
            { text: 'Status', value: 'status_cupom' },
        ];
        this.statusCuponsItems = [];
        this.qtd_matriculas = 0;
        this.qtd_cupons = 0;
        this.taxa_conversao = 0;
        this.qtd_matriculas_anterior = 0;
        this.qtd_cupons_anterior = 0;
        this.taxa_conversao_anterior = 0;
        this.cuponsPorData = [];
        this.graficoDonutDigitalNacionalItemsMidia = [];
        this.graficoDonutDigitalNacionalItemsOrigem = [];
        this.performanceCampanhasItems = [];
        this.cuponsPorCampanhaItems = [];
        this.selectPeriodDate = [];
        this.marketingFiltrosOptions = {
            campanha: [],
            parceria: [],
            tipo_cupom: [],
            digital_origem: [],
            digital_campanha: [],
        };
        this.marketingFiltrosSelectedCampanha = [];
        this.marketingFiltrosSelectedParceria = [];
        this.marketingFiltrosSelectedTipoCupom = [];
        this.marketingFiltrosSelectedDigitalOrigem = [];
        this.marketingFiltrosSelectedDigitalCampanha = [];
    }
    subtrairDias(data, dias) {
        const dataObj = new Date(data);
        dataObj.setDate(dataObj.getDate() - dias);
        // Ajusta para o formato YYYY-MM-DD
        const ano = dataObj.getFullYear();
        const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // Mês começa do 0
        const dia = String(dataObj.getDate()).padStart(2, '0');
        return `${ano}-${mes}-${dia}`;
    }
    async buscar() {
        this.loadingProgressBars = true;
        this.loadingPerformanceCampanhas = true;
        this.loadingRetorno = true;
        this.loadingMidiaOrigem = true;
        this.loadingStatusCupom = true;
        this.loadingFiltros = true;
        const filtros = {
            campanha: this.marketingFiltrosSelectedCampanha,
            parceria: this.marketingFiltrosSelectedParceria,
            tipo_cupom: this.marketingFiltrosSelectedTipoCupom,
            digital_origem: this.marketingFiltrosSelectedDigitalOrigem,
            digital_campanha: this.marketingFiltrosSelectedDigitalCampanha,
        };
        const inicio = new Date(this.selectPeriodDate[0]);
        const fim = new Date(this.selectPeriodDate[1]);
        this.configChartPerformanceCampanhas.xAxis = {
            type: 'datetime',
            min: inicio.getTime(),
            max: fim.getTime(),
        };
        // @ts-ignore
        const diferencaEmDias = Math.ceil((fim - inicio) / (1000 * 60 * 60 * 24));
        const data_inicio_anterior_str = this.subtrairDias(this.selectPeriodDate[0], diferencaEmDias);
        const data_fim_anterior_str = this.subtrairDias(this.selectPeriodDate[1], diferencaEmDias);
        // -------- BARRAS DE PROGRESSO --------
        const marketing_metrics = await marketingDigitalStore.marketingDigitalGetDataDriven({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            filtros
        });
        const marketing_metrics_anterior = await marketingDigitalStore.marketingDigitalGetDataDriven({
            data_inicial: data_inicio_anterior_str,
            data_final: data_fim_anterior_str,
            filtros
        });
        if (marketing_metrics) {
            if (marketing_metrics_anterior) {
                this.qtd_matriculas_anterior = marketing_metrics_anterior.matriculas.value;
                this.qtd_cupons_anterior = marketing_metrics_anterior.cupons_qtd.value;
                this.taxa_conversao_anterior = marketing_metrics_anterior.taxa_conversao.value;
            }
            this.qtd_matriculas = marketing_metrics.matriculas.value;
            this.qtd_cupons = marketing_metrics.cupons_qtd.value;
            this.taxa_conversao = marketing_metrics.taxa_conversao.value;
            this.loadingProgressBars = false;
            // -------- BARRAS DE PROGRESSO --------
            // -------- PERFORMANCE CAMPANHAS --------
            const performance_campanhas_grafico = await marketingDigitalStore.marketingDigitalGetDataDrivenPerformanceGrafico({
                data_inicial: this.selectPeriodDate[0],
                data_final: this.selectPeriodDate[1],
                filtros
            });
            this.cuponsPorData = performance_campanhas_grafico.cupons_por_data;
            this.performanceCampanhasItems = performance_campanhas_grafico.tabela_dados;
            this.cuponsPorCampanhaItems = performance_campanhas_grafico.cupons_por_campanha;
            this.configChartPerformanceCampanhas.series[0] = {
                name: 'Cupons',
                data: this.cuponsPorData.map(d => ({ x: new Date(d.cupom_data_cadastro).getTime(), y: d.value }))
            };
            const somaValores = this.cuponsPorData.reduce((acc, d) => acc + d.value, 0);
            const media = somaValores / this.cuponsPorData.length;
            const linhaMedia = [
                { x: inicio.getTime(), y: media },
                { x: fim.getTime(), y: media }
            ];
            const serieMedia = {
                name: 'Média',
                data: linhaMedia,
                type: 'line',
                color: 'red',
                dashStyle: 'Dash',
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            };
            this.configChartPerformanceCampanhas.series[1] = serieMedia;
            const sortedCuponsPorCampanhaItems = this.cuponsPorCampanhaItems.sort((a, b) => b.y - a.y);
            const campanhas = sortedCuponsPorCampanhaItems.map(item => item.name);
            this.configChartBar.series[0] = {
                name: 'Quantidade',
                data: sortedCuponsPorCampanhaItems.map(item => ({
                    name: item.name,
                    y: item.y
                }))
            };
            this.configChartBar.xAxis.categories = campanhas;
            this.loadingPerformanceCampanhas = false;
            // -------- PERFORMANCE CAMPANHAS --------
            // -------- GRAFICO DONUT --------
            const graficoDonutDigitalNacionalItems = await marketingDigitalStore.marketingDigitalGetDataDrivenGraficoDonut({
                data_inicial: this.selectPeriodDate[0],
                data_final: this.selectPeriodDate[1],
                filtros: filtros
            });
            this.graficoDonutDigitalNacionalItemsMidia = graficoDonutDigitalNacionalItems.midia;
            this.graficoDonutDigitalNacionalItemsOrigem = graficoDonutDigitalNacionalItems.origem;
            this.loadingMidiaOrigem = false;
            // -------- GRAFICO DONUT --------
            // -------- STATUS DOS CUPONS --------
            this.statusCuponsItems = await marketingDigitalStore.marketingDigitalGetDataDrivenCupons({
                data_inicial: this.selectPeriodDate[0],
                data_final: this.selectPeriodDate[1],
                filtros
            });
            this.loadingStatusCupom = false;
            // -------- STATUS DOS CUPONS --------
            // -------- FILTROS --------
            this.marketingFiltrosOptions = await marketingDigitalStore.marketingDigitalGetDataDrivenOptions({ data_inicial: this.selectPeriodDate[0], data_final: this.selectPeriodDate[1] });
            this.marketingFiltrosSelectedCampanha = [];
            this.marketingFiltrosSelectedParceria = [];
            this.marketingFiltrosSelectedTipoCupom = [];
            this.marketingFiltrosSelectedDigitalOrigem = [];
            this.marketingFiltrosSelectedDigitalCampanha = [];
            this.loadingFiltros = false;
            // -------- FILTROS --------
            // -------- UPDATE HIGHCHARTS --------
            this.$nextTick(() => {
                // @ts-ignore
                this.$refs.highchartsComponent.chart.update(this.configChartPerformanceCampanhas, true);
                // @ts-ignore
                this.$refs.highchartsComponentCuponsPorCampanha.chart.update(this.configChartBar, true);
            });
            // -------- UPDATE HIGHCHARTS --------
        }
    }
    async mounted() {
        this.$nextTick(async () => {
            this.loadingFiltros = true;
            this.marketingFiltrosOptions = await marketingDigitalStore.marketingDigitalGetDataDrivenOptions({ data_inicial: this.selectPeriodDate[0], data_final: this.selectPeriodDate[1] });
            this.loadingFiltros = false;
        });
    }
};
DataDrivenMarketingComponent = __decorate([
    Component({
        components: {
            highcharts: Chart,
            BarraProgressoComponent,
            PeriodSelectorComponent,
            GraficoDonutComponent
        },
    })
], DataDrivenMarketingComponent);
export default DataDrivenMarketingComponent;
